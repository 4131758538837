import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import Footer from "../components/Footer";

const StyledContainer = styled(Container)`
  background: var(--background-color2);
`;

const PrivacyPage = () => {
  return (
    <Layout>
      <NavOne />
      <StyledContainer fluid>
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} lg={8} className="text-center my-5">
              <h1 className="mb-3">Privacy Policy</h1>
              <p className="mb-5">Last Updated: February 21, 2022</p>
              <p className="mb-5">
                At ActionforDemocracy.org, we respect and protect the privacy of
                our constituents and those who use our website. The following
                Privacy Statement provides details about how your personal
                information is collected and used by ActionforDemocracy This
                privacy statement applies to ActionforDemocracy.org website and
                services that are located in, but not limited to the
                ActionforDemocracy.org domain.
              </p>
              <h2 className="mb-3">Information Collection and Use</h2>
              <p>
                ActionForDemocracy is the sole owner of information collected on
                this site. We will not sell, share, or rent this information to
                others in ways different from what is disclosed in this
                statement. ActionForDemocracy collects information from our site
                visitors at several different points on our website.
              </p>
              <p className="mb-5">
                Our website offers several opportunities for visitors to
                register for informational mailings. These registration forms
                are often linked to ActionForDemocracy mailing lists and require
                users to give contact information (such as name and email
                address). We use this contact information to send users
                information about ActionForDemocracy fundraising events, news
                updates and new initiatives. Users may opt-out of receiving
                future mailings at any time.
              </p>
              <h2 className="mb-3">Sharing and Usage</h2>
              <p className="mb-3">
                We will never share, sell, or rent your personal information
                with third parties for their promotional use. Occasionally, we
                enter into contracts with third parties so that they can assist
                us in servicing you (for example, providing customer service).
                The contracts prohibit them from using any of your personal
                information for their own purposes, and they are required to
                maintain the confidentiality of the information we provide to
                them. We may disclose or report personal information in limited
                circumstances where we believe in good faith that disclosure is
                required under the law. For example, we may be required to
                disclose personal information to cooperate with regulators or
                law enforcement authorities, to comply with a legal process such
                as court order, subpoena, search warrant, or law enforcement
                request.
              </p>
              <p className="mb-3">
                We also use your information to comply with campaign finance
                laws. We are often required to disclose information regarding
                contributions to ActionForDemocracy to comply with federal
                campaign finance laws.
              </p>
              <p className="mb-5">
                Your information may also be used as we believe to be necessary
                or appropriate: (a) under applicable law; (b) to comply with
                legal process; (c) to respond to requests from public and
                government authorities including public and government
                authorities; (d) to enforce our terms and conditions; (e) to
                protect our operations; (f) to protect our rights, privacy,
                safety or property; and (g) to allow us to pursue available
                remedies or limit the damages that we may sustain.
              </p>
              <h2 className="mb-3">Use of Web Technologies</h2>
              <p className="mb-3">
                We use a variety of technologies on our site. Among these are
                cookies; a piece of information that our websites provide to
                your browser when you visit our sites. Cookies allow us to
                verify the login status of customers using services linked
                directly with our website, track point of entry to point of
                registration for those users participating in our email signup
                programs, and to track and measure the success of a particular
                email blast. Cookies also allow us to track overall site usage
                and determine areas users prefer, enabling us to make your visit
                to our website easier by recognizing you when you return and
                helping to provide you with a customized experience. Usage of a
                cookie is in no way linked to any personally identifiable
                information while on our site. If you choose to disable cookies,
                you may still use our site; however, you may have limited access
                to some areas within our websites.
              </p>
              <p className="mb-5">
                We also keep track of activity on our website via log files
                stored on our web servers. We collect information such as IP
                address, browser type and version, and pages you view. We also
                keep track of how you got to our site and any links you click on
                to leave our site. Once you leave our site, we do not track you.
                We use your website activity to assist us in offering you a
                personalized Web experience, assist you with technical support,
                diagnose problems with our server, administer our websites and
                to tailor our informational offerings to you.
              </p>
              <h2 className="mb-3">Security</h2>
              <p className="mb-3">
                We take every precaution to protect the confidentiality and
                security of your personal information by using
                industry-recognized security safeguards such as firewalls,
                coupled with carefully developed security procedures to protect
                your information from loss, misuse or unauthorized alteration.
                When we ask for sensitive information, such as credit card
                numbers, we protect it through the use of encryption during
                transmission, such as the Secure Socket Layer (SSL) protocol.
              </p>
              <p className="mb-3">
                ActionForDemocracy takes care to reinforce the importance of our
                website visitors' security and privacy among our employees. Our
                employees are trained and required to safeguard your information
                and, using physical, electronic and procedural safeguards, we
                restrict access to personal information to those employees and
                agents for business purposes only.
              </p>
              <p className="mb-5">
                We comply with the California Online Privacy Protection Act. As
                part of that Act, all users of our site may make any changes to
                their information at any time contacting us as described below.
                Changes to billing and/or credit card information must be made
                through our staff, or through our payment processor. Please
                contact us as described below with any questions.
              </p>
              <h2 className="mb-3">Notification of Changes</h2>
              <p className="mb-5">
                If we decide to change our privacy policy, we will post those
                changes on the ActionForDemocracy.org Website, so our users are
                always aware of what information we collect, how we use it, and
                under what circumstances, if any, we disclose it.
              </p>
            </Col>
          </Row>
        </Container>
      </StyledContainer>
      <Footer />
    </Layout>
  );
};

export default PrivacyPage;
